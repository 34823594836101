<script>
import simplebar from "simplebar-vue";
import { layoutComputed } from "@/state/helpers";

import { ApiClient } from "@/common/http-common"


export default {
  components: {
    simplebar,
  },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menuItems: [],
      expandedMenus: []
    };
  },
  computed: {
    ...layoutComputed,
  },
  mounted: function () {
    this.getMenuItems()
  },
  methods: {
    checkPermission: function (response, p) {
      var returnValue = false
      response.data.item.permissions.forEach(function (permission) {
        if (permission.permission.type == p) {
          returnValue = true
        }
      })
      return returnValue
    },
    getMenuItems: function () {
      const self = this;
      ApiClient().get("/v1/admin/user/permission").then(function (response) {

        var dataValue = btoa(JSON.stringify({ platformId: localStorage.getItem("platformId") ?? "" }));

        self.$set(self.menuItems, self.menuItems.length, {
          label: 'sidebar.dashboard',
          icon: 'ri-dashboard-line',
          link: '/'
        });

        var configurationAdministrator = []

        if (self.checkPermission(response, "MODULE_SUPER")) {
          configurationAdministrator.push({
            label: 'sidebar.platforms',
            link: '/platform'
          })
          configurationAdministrator.push({
            label: 'sidebar.applications',
            link: '/applications'
          })
        }
        if (configurationAdministrator.length != 0) {
          self.$set(self.menuItems, self.menuItems.length, {
            label: 'sidebar.super',
            icon: 'ri-dashboard-line',
            subItems: configurationAdministrator
          });
        }


        var configurationList = []

        if (self.checkPermission(response, "MODULE_SETTINGS_PERMISSIONS")) {
          configurationList.push({
            label: 'sidebar.permission',
            link: '/permission?data=' + dataValue
          })
        }
        if (self.checkPermission(response, "MODULE_SETTINGS_CURRENCY")) {
          configurationList.push({
            label: 'sidebar.currency',
            link: '/currency?data=' + dataValue
          })
        }
        if (self.checkPermission(response, "MODULE_SETTINGS_COUNTRIES")) {
          configurationList.push({
            label: 'sidebar.countries',
            link: '/country?data=' + dataValue
          })
        }
        if (self.checkPermission(response, "MODULE_SETTINGS_LANGUAGES")) {
          configurationList.push({
            label: 'sidebar.languages',
            link: '/languages?data=' + dataValue
          })
        }
        if (configurationList.length != 0) {
          self.$set(self.menuItems, self.menuItems.length, {
            label: 'sidebar.configuration',
            icon: 'ri-dashboard-line',
            subItems: configurationList
          });
        }

        var catalogList = []
        if (self.checkPermission(response, "MODULE_SETTINGS_CATEGORY")) {
          catalogList.push({
            label: 'sidebar.category',
            link: '/category?data=' + dataValue
          })
        }

        if (self.checkPermission(response, "MODULE_SETTINGS_TAG")) {
          catalogList.push({
            label: 'sidebar.tags',
            link: '/tags?data=' + dataValue
          })
        }
        if (self.checkPermission(response, "MODULE_SETTINGS_BRANDS")) {
          catalogList.push({
            label: 'sidebar.brands',
            link: '/brands?data=' + dataValue
          })
        }
        if (self.checkPermission(response, "MODULE_SETTINGS_TAXES")) {
          catalogList.push({
            label: 'sidebar.tax',
            link: '/tax?data=' + dataValue
          })
        }
        if (self.checkPermission(response, "MODULE_SETTINGS_PRODUCTS")) {
          catalogList.push({
            label: 'sidebar.products',
            link: '/products?data=' + dataValue
          })
        }

        if (catalogList.length != 0) {
          self.$set(self.menuItems, self.menuItems.length, {
            label: 'sidebar.catalog',
            icon: 'ri-dashboard-line',
            subItems: catalogList
          });
        }

        var clientsList = []

        if (self.checkPermission(response, "MODULE_SETTINGS_CLIENTS")) {
          clientsList.push({
            label: 'sidebar.clients',
            link: '/client?data=' + dataValue
          })
        }

        if (clientsList.length != 0) {
          self.$set(self.menuItems, self.menuItems.length, {
            label: 'sidebar.clients',
            icon: 'ri-dashboard-line',
            subItems: clientsList
          });
        }
        var agentList = []

        if (self.checkPermission(response, "MODULE_SETTINGS_AGENTS")) {
          agentList.push({
            label: 'sidebar.agent',
            link: '/agent?data=' + dataValue
          })
        }

        if (agentList.length != 0) {
          self.$set(self.menuItems, self.menuItems.length, {
            label: 'sidebar.agent',
            icon: 'ri-dashboard-line',
            subItems: agentList
          });
        }


      }).catch(
        function () {
        }
      )
    },
    hasItems(item) {
      return item.subItems && item.subItems.length > 0;
    },
    toggleMenu(item) {
      if (this.isExpanded(item)) {
        this.expandedMenus = this.expandedMenus.filter(menu => menu !== item);
      } else {
        this.expandedMenus.push(item);
      }
    },
    isExpanded(item) {
      return this.expandedMenus.includes(item);
    },
    toggleSubMenu(item) {
      if (!this.expandedSubMenus[item.id]) {
        this.$set(this.expandedSubMenus, item.id, []);
      }

      if (this.isSubMenuExpanded(item)) {
        this.$set(this.expandedSubMenus, item.id, []);
      } else {
        this.$set(this.expandedSubMenus, item.id, item.subItems);
      }
    },
    isSubMenuExpanded(item, subitem) {
      return this.expandedSubMenus[item.id] && this.expandedSubMenus[item.id].includes(subitem);
    },
    onRoutechange() {
      setTimeout(() => {
        const currentPosition = document.getElementsByClassName("mm-active")[0]
          .offsetTop;
        if (currentPosition > 400)
          this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 200;
      }, 300);
    },
    forcePageReload() {
      window.location.reload(true);
    }
  },
};
</script>
<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100" ref="currentMenu" id="my-element">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <template v-for="item in menuItems">
            <li class="menu-title" v-if="item.isTitle" :key="item.id">
              {{ $t(item.label) }}
            </li>

            <!--end Layouts menu -->
            <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
              <a v-if="hasItems(item)" href="#" class="is-parent" :class="{
            'has-arrow': !item.badge,
            'has-dropdown': item.badge,
            'active': isExpanded(item)
          }" @click="toggleMenu(item)">
                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                <span>{{ $t(item.label) }}</span>
                <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
            $t(item.badge.text) }}</span>
              </a>

              <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                <span>{{ $t(item.label) }}</span>
                <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
            $t(item.badge.text) }}</span>
              </router-link>

              <ul v-if="hasItems(item)" class="sub-menu" :class="{ 'mm-collapse': !isExpanded(item) }"
                aria-expanded="false">
                <li v-for="(subitem, index) of item.subItems" :key="index">
                  <router-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">{{
            $t(subitem.label)
          }}</router-link>
                  <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    @click="toggleSubMenu(item)">
                    {{ subitem.label }}
                  </a>
                  <ul v-if="hasItems(subitem)" class="sub-menu"
                    :class="{ 'mm-collapse': !isSubMenuExpanded(item, subitem) }" aria-expanded="false">
                    <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                      <router-link :to="subSubitem.link" class="side-nav-link-ref">{{ $t(subSubitem.label)
                        }}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>